<template>
  <div class="rightLink">
    <div class="bottomDiv" style="height: 100%">
      <div class="title">用户编辑</div>
      <div class="formDiv">
        <el-form label-position="left" label-width="80px">
          <el-row :gutter="20">
            <el-col :span="7">
              <el-form-item label="选择省份:">
                <el-select
                  style="width: 100%"
                  v-model="FormData.province"
                  placeholder="请选择省"
                  @change="handleProvinceChange"
                  clearable
                >
                  <el-option
                    v-for="provinces in provinceList"
                    :key="provinces.value"
                    :value="provinces.label"
                    :label="provinces.label"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择市:">
                <el-select
                  v-model="FormData.city"
                  placeholder="请选择市"
                  @change="handleCityChange"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="citys in cityList[0]"
                    :key="citys.value"
                    :value="citys.label"
                    >{{ citys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="选择区/县:">
                <el-select
                  v-model="FormData.county"
                  placeholder="请选择区/县"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="countys in countyList[0]"
                    :key="countys.value"
                    :value="countys.label"
                    >{{ countys.label }}</el-option
                  >
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="用户编号:">
                <el-input
                  v-model="FormData.user_number"
                  placeholder="请输入用户编号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="7">
              <el-form-item label="手机号:">
                <el-input
                  v-model="FormData.phone"
                  placeholder="请输入用户手机号"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col v-show="isadmin" :span="7">
              <el-form-item label="运营商:">
                <el-select
                  v-model="FormData.username"
                  placeholder="请选择运营商"
                  style="width: 100%"
                  clearable
                >
                  <el-option
                    v-for="aa in users"
                    :key="aa.username"
                    :value="aa.username"
                    :label="aa.username"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="1">
              <div class="BtnSearch" @click="searchBtn()">查询</div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 表格部分 -->
      <div class="tableDiv">
        <el-table
          ref="multipleTableRef"
          :data="tableData"
          border
          v-loading="loading"
          height="500px"
          style="width: 90%; margin: 20px auto; overflow-y: auto"
        >
          <el-table-column label="序号" type="index" align="center" width="60">
            <template #default="{ $index }">
              {{ (currentPage - 1) * pageSize + $index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="用户编号" prop="user_number" />
          <el-table-column label="用户手机号" prop="phone" />
          <!-- <el-table-column label="用户状态" prop="is_active">
            <template #default="scope">
              <span v-if="scope.row.is_active === true">启用</span>
              <span v-if="scope.row.is_active === false">暂停</span>
            </template>
          </el-table-column> -->
          <el-table-column label="所在省" prop="province" />
          <el-table-column label="所在市" prop="city" />
          <el-table-column label="所在区县" prop="county" />
          <!-- <el-table-column label="邮箱" prop="email" /> -->
          <!-- <el-table-column label="电动车余额" prop="balance" />
          <el-table-column label="电动汽车余额" prop="balance_new" /> -->
          <!-- <el-table-column
            label="所属运营商"
            v-show="isadmin"
            prop="username"
          /> -->
          <el-table-column label="操作" align="center" width="140">
            <template #default="scope">
              <!-- <div
                class="BtnSearch BtnSearch2"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</div
              > -->
              <el-button
                round
                color="#42a7a9"
                plain
                size="mini"
                @click="handleEdit(scope.$index, scope.row)"
                >编辑</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination
            :current-page="currentPage"
            :page-size="pageSize"
            :small="small"
            layout="total, slot, prev, pager, next, jumper"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
            <template #default>
              <span class="el-pagination__total">{{ Math.ceil(total / pageSize) }} 页 </span>
            </template>
          </el-pagination>
        </div>
        <div v-dialogdrag>
          <el-dialog
            v-model="Dialog.editLevy"
            title="编辑区域"
            width="50vw"
            :before-close="closeDialog"
            class="dialog"
          >
            <div class="formDiv">
              <el-form label-position="right" label-width="80px">
                <el-row :gutter="20">
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-form-item label="用户编号:">
                      <el-input
                        :disabled="true"
                        v-model="Dialog.DialogData.user_number"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="8">
                    <el-form-item label="手机号:">
                      <el-input v-model="Dialog.DialogData.phone"></el-input>
                    </el-form-item>
                  </el-col>
                </el-row>

                <el-row :gutter="20">
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-form-item label="选择省份:">
                      <el-select
                        v-model="Dialog.DialogData.province"
                        placeholder="请选择省"
                        @change="handleProvinceChange"
                      >
                        <el-option
                          v-for="provinces in provinceList"
                          :key="provinces.value"
                          :value="provinces.label"
                          :label="provinces.label"
                        />
                        <!-- </el-option> -->
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :span="1"></el-col>
                  <el-col :span="8">
                    <el-form-item label="选择市:">
                      <el-select
                        v-model="Dialog.DialogData.city"
                        placeholder="请选择市"
                        @change="handleCityChange"
                      >
                        <!-- <el-option value="">请选择</el-option> -->
                        <el-option
                          v-for="citys in cityList[0]"
                          :key="citys.value"
                          :value="citys.label"
                          >{{ citys.label }}</el-option
                        >
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>
                <el-row :gutter="20">
                  <el-col :span="2"></el-col>
                  <el-col :span="8">
                    <el-form-item label="选择区/县:">
                      <el-select
                        v-model="Dialog.DialogData.county"
                        placeholder="请选择区/县"
                      >
                        <!-- <el-option value="">请选择</el-option> -->
                        <el-option
                          v-for="countys in countyList[0]"
                          :key="countys.value"
                          :value="countys.label"
                          >{{ countys.label }}</el-option
                        >
                      </el-select>
                    </el-form-item>
                  </el-col>
                </el-row>

                <!-- <el-row :gutter="20">
                <el-col :span="3"></el-col>
                <el-col :span="8">
                  <el-form-item label="余额:">
                    <el-input
                      v-model="Dialog.DialogData.balance"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row> -->
              </el-form>
            </div>
            <template #footer>
              <span class="dialog-footer">
                <el-button class="Btn" @click="closeDialog">取消</el-button>
                <el-button class="Btn" type="primary" @click="submitDialog"
                  >提交</el-button
                >
              </span>
            </template>
          </el-dialog>
        </div>
      </div>
    </div>

    <!-- </div> -->
  </div>
</template>
<script>
import { onMounted, reactive, toRefs } from "vue-demi";
import { sel_user, upd_user, operator_data } from "@/request/api";
import { ElMessage } from "element-plus";
import { regionData } from "element-china-area-data";

// import ProvinceCityCountry from '../../assets/json/address'

export default {
  name: "SearchMan",
  setup() {
    const data = reactive({
      //表单数据
      FormData: {},
      isadmin: false,
      users: [],
      tableData: [],
      loading: false,
      // form:{
      //     province:"",
      //       city:"",
      //       county:""
      //     },
      options: regionData,
      // 省市县
      regionData,
      provinceList: [],
      cityList: [],
      countyList: [],
      province: "",
      city: "",
      county: "",

      //   弹框
      Dialog: {
        editLevy: false,
        DialogData: {},
      },

      //   分页
      currentPage: 1,
      pageSize: 20,
      total: 0,
      checked: 0,
      small: true,
    });

    const searchBtn = () => {
      data.currentPage = 1;
      getList();
    };

    const handleEdit = (index, row) => {
      console.log(row, "--=");
      data.Dialog.editLevy = true;
      data.Dialog.DialogData = JSON.parse(JSON.stringify(row));
    };

    const initProvinceList = () => {
      // 初始化省份列表数据源
      regionData.forEach((tab) => {
        data.provinceList.push(tab);
      });
      console.log(data.provinceList, "--**99");
    };
    const handleProvinceChange = (val) => {
      console.log(val, "009-");
      data.cityList = [];
      data.countyList = [];
      data.province = val;
      console.log(data.province, "]]");
      initCityList();
    };

    const initCityList = () => {
      console.log(data.province, "--2");
      // 初始化城市列表数据源
      data.provinceList.forEach((tab1) => {
        // console.log(tab1,'--==tab1');
        if (tab1.label == data.province) {
          data.cityList.push(tab1.children);
        }
      });
      console.log(data.cityList, "pppppp");
    };

    const handleCityChange = (value1) => {
      data.countyList = [];
      data.city = value1;
      console.log(data.city, "]]999");
      initCountyList();
    };

    const initCountyList = () => {
      // 初始化县/区列表数据源
      console.log(data.city, "--299");
      // 初始化城市列表数据源
      data.cityList[0].forEach((tab2) => {
        console.log(tab2, "tab2");
        // tab2.forEach((tab3)=>{
        //   console.log(tab3,'tab3');
        //    if(tab3.label==this.selectedCity){
        //  this.countyList.push(tab3.children)
        // }
        // })
        if (tab2.label == data.city) {
          data.countyList.push(tab2.children);
        }
      });
      console.log(data.countyList, "pppppp999");
    };

    // const userLogout = (index, row) => {
    //   logout(row).then((res) => {
    //     if (res) {
    //       if (res.code == 200) {
    //         ElMessage({
    //           showClose: true,
    //           message: "注销成功",
    //           type: "success",
    //         });
    //         this.data.tableData.entry_date = "已注销"
    //         getList()
    //       } else {
    //         ElMessage.error(res.msg);
    //       }
    //     } else {
    //       ElMessage.error("注销失败");
    //     }
    //   });
    // };

    const closeDialog = () => {
      data.Dialog.editLevy = false;
    };
    const submitDialog = () => {
      upd_user(data.Dialog.DialogData).then((res) => {
        console.log(res);
        if (res) {
          closeDialog();
          if (res.code == 200) {
            ElMessage({
              showClose: true,
              message: "编辑成功",
              type: "success",
            });
            getList();
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("编辑失败");
        }
      });
    };

    const getList = () => {
      const dataa = {
        operator_id: sessionStorage.getItem("userID"),
        username: data.FormData.username,
        province: data.FormData.province,
        city: data.FormData.city,
        county: data.FormData.county,
        user_number: data.FormData.user_number,
        phone: data.FormData.phone,
        page: data.currentPage,
      };
      console.log(dataa, "data.FormData11");

      data.loading = true;
      sel_user(dataa).then((res) => {
        console.log(res);
        if (res) {
          data.loading = false;
          if (res.code == 200) {
            data.tableData = res.data;
            data.total = res.count_data;
          } else {
            ElMessage.error(res.msg);
          }
        } else {
          ElMessage.error("数据获取失败");
        }
      });
    };
    const getUsername = () => {
      const username1 = localStorage.getItem("username");
      const users1 = [];
      operator_data().then((res) => {
        console.log(res, "sssssss");
        data.users = res;
        res.forEach((a) => {
          users1.push(a.username);
        });
        if (users1.indexOf(username1) == -1) {
          data.isadmin = true;
        } else {
          data.isadmin = false;
          data.FormData.username = username1;
        }
      });
    };
    const handleSizeChange = (val) => {
      data.pageSize = val;
    };
    const handleCurrentChange = (val) => {
      data.currentPage = val;
      getList();
      //
    };
    onMounted(() => {
      console.log(data);
      getList();
      getUsername();
      initProvinceList();
      // handleChange1()
    });
    return {
      ...toRefs(data),
      searchBtn,
      getList,
      handleEdit,
      // handleChange1,
      initProvinceList,
      handleProvinceChange,
      handleCityChange,
      initCityList,
      initCountyList,
      // userLogout,
      closeDialog,
      submitDialog,
      handleSizeChange,
      handleCurrentChange,
      getUsername,
    };
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: space-between;
  padding: 0 67px;
  margin-top: -10px;
}

.bottomDiv {
  min-height: 180px;
}
.p_bottom .el-icon {
  float: right;
  color: green;
}
.s_water {
  line-height: 40px;
  font-size: 19px;
  /* font-weight: bold; */
}
.s_profress {
  line-height: 25px;
}
.el-progress /deep/ .el-progress-bar__outer {
  --el-border-color-lighter: #fff;
}

.BtnSearch2 {
  float: left;
  width: 100px;
  margin: 0 4px;
}
</style>







